import React from "react";
import { Route, Switch } from "react-router-dom";

//components
import Home from "./components/home";
import PageNoAccess from "./components/pageNoAccess";

const Routes = () => {
  return (
    <Switch>
      <Route path="/user/reviews" exact component={Home}></Route>
      <Route component={PageNoAccess}></Route>
    </Switch>
  );
};
export default Routes;
