import React, { Component } from "react";
import Reviews from "./reviews";
import ipapi from 'ipapi.co';
import { getUA, device, engine, os, browser } from 'react-device-detect';

class App extends Component {
  render() {
    return <Reviews reviews={this.state.reviews} user={this.state.user} />;
  }

  state = {
    reviews: [],
    user: []
  };

  componentWillMount() {
    // fetch('https://api.ipify.org?format=jsonp?callback=?', {
    //   method: 'GET',
    //   headers: {},
    // })
    //   .then(res => {

    //     return res.text()
    //   }).then(ip => {
    //     console.log('ip', ip);

    //     ipapi.location(res => {
    //       const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(res)
    //       };
    //     })

    //   });
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const appSecret = query.get("appSecret");
    const appId = query.get("appId");

    fetch('https://api.ipify.org?format=jsonp?callback=?', {
      method: 'GET',
      headers: {},
    })
      .then(res => {
        return res.text()
      }).then(ip => {
        //console.log('ip', ip);
        ipapi.location(res => {
          const location = res;
          location.ip = ip;
          const data = { location: location, ua: { device: device, engine: engine, os: os, browser: browser, getUA: getUA } }
          fetch(`https://api.evabot.ai/reviews/user/getReviews/${appId}/${appSecret}`,
            { method: "post", body: JSON.stringify(data) }
          )
            .then((res) => res.json())
            .then(({ reviews, user }) => {
              console.log("data", user[0]);
              this.setState({
                reviews: reviews,
                user: user[0] === undefined ? {} : user[0],
              });
            })
            .catch(console.log);
        })
      });
  }
}

export default App;
