import React, { Component } from "react";
import StarRatings from "react-star-ratings";
import Review from "./review";
class Reviews extends Component {
  render() {
    const { reviews, user } = this.props;
    console.log(this.props.user);
    return (
      <div>
        <div className="card">
          <a
            href={user.ProfileUrl}
            target="_blank"
            style={{ color: "#0073bb", textDecoration: "none" }}
          >
            <div className="card-header bg-white">
              <h5 className="mb-0">
                {user.Name} reviews on EvaBot
                <img
                  src="http://content.web-repository.com/s/41647292542357322/uploads/Home_-_V2/eva-logo_1-3392874.png"
                  style={{ width: "50px", float: "right" }}
                />
              </h5>
              <div>
                <StarRatings
                  rating={5}
                  starRatedColor="#FAC917"
                  numberOfStars={5}
                  name="rating"
                  starDimension="17px"
                  starSpacing="0px"
                />{" "}
                <small className="text-muted">
                  {user.TotalReviews} reviews
                </small>
              </div>
            </div>
          </a>
          <div
            className="card-body pt-0"
            style={{
              height: "250px",
              overflow: "auto",
            }}
          >
            {reviews.map((review) => (
              <Review review={review} key={review.Key} />
            ))}
            {/* {reviews.length === 0 && "No reviews found..!!"} */}
          </div>
          <a
            href={user.ProfileUrl}
            target="_blank"
            style={{ color: "#0073bb", textDecoration: "none" }}
          >
            <div className="card-footer text-center p-1">Read all reviews</div>
          </a>
        </div>
        <center>
          <h6></h6>
        </center>
      </div>
    );
  }
}

export default Reviews;
