import React, { Component } from "react";
import StarRatings from "react-star-ratings";

class Review extends Component {
  render() {
    const { review } = this.props;
    return (
      <div
        className="card border border-top-0 border-right-0 border-left-0"
        key={review.Key}
      >
        <div className="card-body m-0 p-0 mb-1 mt-2">
          <div className="card-title mb-0">
            <h6 className="m-0">{review.Name}</h6>
            <div className="card-subtitle mb-1 text-muted">
              <StarRatings
                rating={5}
                starRatedColor="#FAC917"
                numberOfStars={5}
                name="rating"
                starDimension="14px"
                starSpacing="0px"
              />
              <small style={{ fontSize: 11 }}> {review.AcceptedOn}</small>
            </div>
          </div>
          <p className="card-text">{review.SenderMessage}</p>
        </div>
      </div>
    );
  }
}

export default Review;
