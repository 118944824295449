import React from "react";

const PageNoAccess = () => {
  return (
    <div className="wrapper no-access">
      <div className="no-access-block">
        <p>Access denied</p>
        <p>
          Please contact us at <b>care@evabot.ai</b>
        </p>
      </div>
    </div>
  );
};

export default PageNoAccess;
